import { useMemo, lazy, Suspense, useEffect, useState } from 'react'
import Loading from '@/components/shared/Loading'
import {
    setPageModule,
    setVerifiedOrgId,
    signIdToken,
    // setVerifiedEmail,
    useAppDispatch,
    useAppSelector,
} from '@/store'

import {
    LAYOUT_TYPE_CLASSIC,
    LAYOUT_TYPE_MODERN,
    LAYOUT_TYPE_SIMPLE,
    LAYOUT_TYPE_STACKED_SIDE,
    LAYOUT_TYPE_DECKED,
    LAYOUT_TYPE_BLANK,
} from '@/constants/theme.constant'
import useAuth from '@/utils/hooks/useAuth'
import { IdToken, useAuth0 } from '@auth0/auth0-react'
import useDirection from '@/utils/hooks/useDirection'
import useLocale from '@/utils/hooks/useLocale'
import localStorage from 'redux-persist/es/storage'
import deepParseJson from '@/utils/deepParseJson'
// import { useNavigate } from 'react-router-dom'
// import { useNavigate } from 'react-router-dom'
// import VerificationMail from '../../views/auth/verificationMail'
// import { Navigate } from 'react-router-dom'
// import CreateFrom from '../../views/OrganizationCreate/CreateFrom'

const layouts = {
    [LAYOUT_TYPE_CLASSIC]: lazy(() => import('./ModernLayout')),
    [LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
    [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import('./ModernLayout')),
    [LAYOUT_TYPE_SIMPLE]: lazy(() => import('./ModernLayout')),
    [LAYOUT_TYPE_DECKED]: lazy(() => import('./ModernLayout')),
    [LAYOUT_TYPE_BLANK]: lazy(() => import('./ModernLayout')),
}

const Layout = () => {
    const dispatch = useAppDispatch()
    const [email, setEmail] = useState<boolean | undefined>(false)
    const [orgId, setOrgId] = useState<string>()
    const { isAuthenticated, getIdTokenClaims, isLoading } = useAuth0()
    const layoutType = useAppSelector((state) => state.theme.layout.type)
    // const navigate = useNavigate()
    // const organizationId = useAppSelector(state => state.auth.user.is_organization)
    const { signIn } = useAuth()
    useDirection()
    useLocale()

    useEffect(() => {
        localStorage
            .getItem('module')
            .then((data) => {
                const res = deepParseJson(data)
                dispatch(setPageModule(res))
            })
            .catch((error) => console.log(error))
    }, [dispatch])

    // const handleTokenClaims = useCallback((res: IdToken | undefined) => {
    //     dispatch(signIdToken({ signedIn: true, id_token: res }));
    //     setEmail(res?.email_verified);
    //     setOrgId(res?.Organization.id);
    // }, [dispatch]);

    useEffect(() => {
        if (isAuthenticated) {
            getIdTokenClaims()
                .then((res) => {
                    dispatch(
                        signIdToken({
                            signedIn: true,
                            id_token: res as IdToken,
                        })
                    )
                    setEmail(res?.email_verified)
                    setOrgId(res?.Organization.id)
                    // handleTokenClaims(res as IdToken | undefined)
                    dispatch(setVerifiedOrgId(res?.Organization.id))
                })
                .catch((err) => {
                    console.log('Users', err)
                })
        }
    }, [dispatch, getIdTokenClaims, isAuthenticated])

    useEffect(() => {
        if (isAuthenticated && email) {
            signIn()
        }
    }, [email, isAuthenticated, signIn])

    // console.log("Auth", isAuthenticated, "Email auth or not", email, "Loading", isLoading)
    // console.log("Hii,org", orgId, organizationId)
    const AppLayout = useMemo(() => {
        if (isAuthenticated && !isLoading) {
            if (!email) {
                // console.log(
                //     "Hii Email Verification..."
                // )
                return lazy(() => import('../../views/auth/verificationMail'))
            } else if (!orgId) {
                console.log('Hii Organization Creation...')
                return lazy(
                    () => import('../../views/OrganizationCreate/CreateFrom')
                )
            } else {
                console.log('Hii Client Welcome😊')
                return layouts[layoutType]
            }
        }
        return lazy(() => import('./AuthLayout'))
    }, [isAuthenticated, isLoading, email, orgId, layoutType])

    return (
        <Suspense
            fallback={
                <div className="flex flex-auto flex-col h-[100vh]">
                    <Loading loading={true} />
                </div>
            }
        >
            {isLoading ? <Loading loading={true} /> : <AppLayout />}
        </Suspense>
    )
}
export default Layout
