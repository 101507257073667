import { combineReducers } from '@reduxjs/toolkit'
import automationFlow, { AutomationState } from './automationSlice'

const reducer = combineReducers({
    automationFlow,
})


export type AutomationFlowState = {
    automationFlow: AutomationState
}

export * from './automationSlice'

export default reducer
