import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SLICE_BASE_NAME } from './constants'

export type UserState = {
    avatar?: string
    userName?: string
    is_verified?: boolean
    is_organization?: string
    email?: string
    phoneNumber?: string
    country?: string
    authority?: string[]
    display_name?: string
    org_country?: string
    org_tmz?: string
    org_url?: string
    org_legal_name?: string
    org_legal_id?: string
    error?: string
}

export const initialState: UserState = {
    avatar: '',
    userName: '',
    email: '',
    phoneNumber: '',
    country: '',
    authority: [],
    display_name: '',
    org_country: '',
    org_tmz: '',
    org_url: '',
    org_legal_name: '',
    org_legal_id: '',
    is_verified: false,
    is_organization: '',
    error: ''

}

const userSlice = createSlice({
    name: `${SLICE_BASE_NAME}/user`,
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<UserState>) {
            state.avatar = action.payload?.avatar
            state.email = action.payload?.email
            state.userName = action.payload?.userName
            state.authority = action.payload?.authority
            state.phoneNumber = action.payload?.phoneNumber
            state.country = action.payload?.country
        },
        setOrganization(state, action: PayloadAction<UserState>) {
            state.display_name = action.payload?.display_name
            state.org_country = action.payload?.org_country
            state.org_tmz = action.payload?.org_tmz
            state.org_url = action.payload?.org_url
            state.org_legal_id = action.payload?.org_legal_id
            state.org_legal_name = action.payload?.org_legal_name
        },
        setVerifiedEmail: (state, action: PayloadAction<boolean | undefined>) => {
            state.is_verified = action.payload
        },
        setVerifiedOrgId: (state, action: PayloadAction<string>) => {
            state.is_organization = action.payload
        },
        setErrorGlobally: (state, action: PayloadAction<string>) => {
            state.error = action.payload
        }
    },
})

export const { setUser, setOrganization, setErrorGlobally, setVerifiedEmail, setVerifiedOrgId } = userSlice.actions
export default userSlice.reducer
