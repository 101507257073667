import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SLICE_BASE_NAME } from './constants'

export interface AutomationState {
    color: string
    title: string
    titleMappingCard: string
    ids: number
    typeNode: string
    badgeId: number
}

const initialState: AutomationState = {
    color: "danger",
    title: "",
    titleMappingCard: "Ads",
    ids: -1,
    typeNode: "",
    badgeId: 0
}

const automationSlice = createSlice({
    name: `${SLICE_BASE_NAME}/automationFlow`,
    initialState,
    reducers: {
        setColorAutomation(state, action: PayloadAction<string>) {
            state.color = action.payload
        },
        setTitleAutomation(state, action: PayloadAction<string>) {
            state.title = action.payload
        },
        setTitleMappingCard(state, action: PayloadAction<string>) {
            state.titleMappingCard = action.payload
        },
        setIds(state, action: PayloadAction<number>) {
            state.ids = action.payload
        },
        setNodeType(state, action: PayloadAction<string>) {
            state.typeNode = action.payload
        },
        setBadgeId(state, action: PayloadAction<number>) {
            state.badgeId = action.payload
        }
    },
})

export const { setColorAutomation, setNodeType, setBadgeId, setTitleAutomation, setTitleMappingCard, setIds } = automationSlice.actions
export default automationSlice.reducer
