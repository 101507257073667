import { PackingBox, Product } from '@/@types/wroPage'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export type FulfillOrderState = {
    singleBox: PackingBox[]
    selectedBox: PackingBox
}

const initialState: FulfillOrderState = {
    singleBox: [],
    selectedBox: {
        id: '',
        box_id: 0,
        order_id: 0,
        product: [],
        weight: 0,
        width: 0,
        height: 0,
        length: 0,
        address: { label: '', value: 0 },
    },
}

export const fulfillOrderSlice = createSlice({
    name: 'fulfillOrder',
    initialState,
    reducers: {
        addSingleBox: (state, action: PayloadAction<PackingBox>) => {
            state.singleBox = state.singleBox.concat(action.payload)
        },
        updateSingleBox: (state, action: PayloadAction<PackingBox>) => {
            state.singleBox?.map((item) => {
                if (item.id === action.payload.id) {
                    item.product = action.payload?.product || item.product
                    item.weight = action.payload?.weight || item.weight
                    item.width = action.payload?.width || item.width
                    item.height = action.payload?.height || item.height
                    item.length = action.payload?.length || item.length
                    item.address = action.payload?.address || item.address
                }
            })
        },
        deleteSingleBox: (state, action: PayloadAction<string>) => {
            state.singleBox = state.singleBox.filter((item) => item.id !== action.payload)
        },
        updateSelectedBox: (state, action: PayloadAction<PackingBox>) => {
            state.selectedBox = action.payload
        },
        addBoxProduct: (state, action: PayloadAction<Product>) => {
            state.selectedBox.product = state.selectedBox.product?.concat(
                action.payload
            )
        },

        updateBoxProduct: (state, action: PayloadAction<Product>) => {
            state.selectedBox.product?.map((item) => {
                if (item.id === action.payload.id) {
                    item.name = action.payload?.name || item.name
                    item.value = action.payload?.value || item.value
                    item.quantity = action.payload?.quantity || item.quantity
                }
            })
        },

        updateSelectedBoxValue: (state, action: PayloadAction<PackingBox>) => {
            state.selectedBox.weight =
                action.payload?.weight || state.selectedBox.weight
            state.selectedBox.length =
                action.payload?.length || state.selectedBox.length
            state.selectedBox.width =
                action.payload?.width || state.selectedBox.width
            state.selectedBox.height =
                action.payload?.height || state.selectedBox.height
            state.selectedBox.address =
                action.payload?.address || state.selectedBox.address
        },

        removeSelectBox: (state) => {
            state.selectedBox.id = ''
            state.selectedBox.product = []
            state.selectedBox.address = { label: '', value: 0 }
            state.selectedBox.box_id = 0
            state.selectedBox.order_id = 0
            state.selectedBox.height = 0
            state.selectedBox.length = 0
            state.selectedBox.weight = 0
            state.selectedBox.width = 0
        },
    },
})
export const {
    addSingleBox,
    updateSingleBox,
    deleteSingleBox,
    removeSelectBox,
    updateSelectedBox,
    addBoxProduct,
    updateBoxProduct,
    updateSelectedBoxValue,
} = fulfillOrderSlice.actions
export default fulfillOrderSlice.reducer
