import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SLICE_BASE_NAME } from './constants'
import { IdToken } from '@auth0/auth0-react'

export interface SessionState {
    signedIn: boolean
    token: string
    id_token: IdToken
}

const initialState: SessionState = {
    signedIn: false,
    token: "",
    id_token: {}
}

const sessionSlice = createSlice({
    name: `${SLICE_BASE_NAME}/session`,
    initialState,
    reducers: {
        signIdToken(state, action: PayloadAction<{ signedIn: boolean, id_token: IdToken }>) {
            state.signedIn = action.payload.signedIn
            state.id_token = action.payload.id_token
        },
        signInSuccess(state, action: PayloadAction<string>) {
            state.signedIn = true
            state.token = action.payload
        },
        signOutSuccess(state) {
            state.signedIn = false
            state.token = ""
            state.id_token = {}
        },
    },
})

export const { signInSuccess, signOutSuccess, signIdToken } = sessionSlice.actions
export default sessionSlice.reducer
