import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type productUpdateState = {
    updateSingleVariant: boolean
}

export const initialState: productUpdateState = {
    updateSingleVariant: false,
}

export const productUpdate = createSlice({
    name: 'updateVariant',
    initialState,
    reducers: {
        setUpdateProduct: (state, action: PayloadAction<boolean>) => {
            state.updateSingleVariant = action.payload
        },
    },
})

export const { setUpdateProduct } = productUpdate.actions

export default productUpdate.reducer
