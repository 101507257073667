import { PalletBox, SingleBox } from '@/@types/wroPage'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export type PageState = {
    floorLoaded: boolean
    pallet: PalletBox[]
    single: SingleBox[]
    selectedSingle: SingleBox[]
    selectedPallet: PalletBox
}

const initialState: PageState = {
    floorLoaded: false,
    pallet: [],
    single: [],
    selectedSingle: [],
    selectedPallet: {
        id: NaN,
        group: [],
        length: 0,
        width: 0,
        height: 0,
        weightPallet: 0,
    },
}

export const pageSlice = createSlice({
    name: 'page',
    initialState,
    reducers: {
        setFloorLoaded: (state, action: PayloadAction<boolean>) => {
            state.floorLoaded = action.payload
        },
        addBox: (state, action: PayloadAction<SingleBox>) => {
            state.single = state.single.concat(action.payload)
        },
        deleteBox: (state, action: PayloadAction<number>) => {
            state.single = state.single.filter(
                (param) => param.id !== action.payload
            )
        },
        selectBox: (state, action: PayloadAction<SingleBox>) => {
            state.selectedSingle = state.selectedSingle.concat(action.payload)
        },
        duplicateBox: (state, action: PayloadAction<number>) => {
            for (let count = 0; count < action.payload; count++) {
                let groupSum = 0

                state.pallet?.map((res) => (groupSum += res.group.length))

                state.single = state.single.concat({
                    ...state.selectedSingle[0],
                    id: state.single.length + groupSum + 1,
                })
            }
        },
        updateBox: (state, action: PayloadAction<SingleBox>) => {
            state.single.map((res) => {
                if (res.id === action.payload.id) {
                    res.product_id = action.payload.product_id
                    res.product = action.payload.product
                    res.quality = action.payload.quality
                    res.weight = action.payload.weight
                    res.dimension = action.payload.dimension
                    return
                }
            })
        },
        removeSelectBox: (state, action: PayloadAction<number>) => {
            state.selectedSingle = state.selectedSingle.filter(
                (param) => param.id !== action.payload
            )
        },
        removeAllBoxes: (state) => {
            state.single = []
        },
        createPallet: (state, action: PayloadAction<SingleBox[]>) => {
            state.pallet = state.pallet.concat({
                id: state.pallet.length + 1,
                group: action.payload,
            })

            action.payload?.map(
                (res) =>
                    (state.single = state.single.filter(
                        (param) => param.id !== res.id
                    ))
            )

            state.selectedSingle = []
        },
        selectPallet: (state, action: PayloadAction<PalletBox>) => {
            state.selectedPallet.id = action.payload.id
            state.selectedPallet.group = action.payload.group
            state.selectedPallet.height = action.payload.height
            state.selectedPallet.width = action.payload.width
            state.selectedPallet.length = action.payload.length
            state.selectedPallet.weightPallet = action.payload.weightPallet
        },
        updatePallet: (state, action: PayloadAction<PalletBox>) => {
            state.pallet?.map((res) => {
                if (res.id === action.payload.id) {
                    res.group = action.payload.group
                    res.length = action.payload.length
                    res.width = action.payload.width
                    res.height = action.payload.height
                    res.weightPallet = action.payload.weightPallet
                    return
                }
            })

            action.payload.group.map((res) => {
                state.single = state.single.filter(
                    (param) => param.id !== res.id
                )
            })
        },
        removeSelectPallet: (state) => {
            state.selectedPallet.id = NaN
            state.selectedPallet.group = []
        },
        unboxPallet: (state, action: PayloadAction<PalletBox>) => {
            state.single = state.single.concat(
                action.payload.group.map((res) => res)
            )

            state.pallet = state.pallet.filter(
                (res) => res.id !== action.payload.id
            )

            state.selectedPallet.id = NaN
            state.selectedPallet.group = []
        },
    },
})
export const {
    setFloorLoaded,
    addBox,
    deleteBox,
    selectBox,
    duplicateBox,
    removeAllBoxes,
    removeSelectBox,
    createPallet,
    selectPallet,
    removeSelectPallet,
    unboxPallet,
    updateBox,
    updatePallet,
} = pageSlice.actions
export default pageSlice.reducer
