import { combineReducers, CombinedState, AnyAction, Reducer } from 'redux'
import auth, { AuthState } from './slices/auth'
import base, { BaseState } from './slices/base'
import locale, { LocaleState } from './slices/locale/localeSlice'
import theme, { ThemeState } from './slices/theme/themeSlice'
import RtkQueryService from '@/services/RtkQueryService'
import page, { PageState } from './slices/pages/pageSlice'
import product, { ProductState } from './slices/pages/productSlice'
import updateVariant, {
    productUpdateState,
} from './slices/scmSingle/prodcutToggle'
import insightSales, {
    InsightSalesState,
} from './slices/pages/insightSalesSlice'
import fulfillOrder, {
    FulfillOrderState,
} from './slices/pages/fulfillOrderSlice'
import automation, { AutomationState } from './slices/automation/automationSlice';

export type RootState = CombinedState<{
    auth: CombinedState<AuthState>
    base: CombinedState<BaseState>
    locale: LocaleState
    theme: ThemeState
    page: PageState
    updateVariant: productUpdateState
    product: ProductState
    insightSales: InsightSalesState
    fulfillOrder: FulfillOrderState
    automation: AutomationState
    /* eslint-disable @typescript-eslint/no-explicit-any */
    [RtkQueryService.reducerPath]: any
}>

export interface AsyncReducers {
    [key: string]: Reducer<any, AnyAction>
}

const staticReducers = {
    auth,
    base,
    locale,
    theme,
    page,
    product,
    insightSales,
    fulfillOrder,
    updateVariant,
    automation,
    [RtkQueryService.reducerPath]: RtkQueryService.reducer,
}

const rootReducer =
    (asyncReducers?: AsyncReducers) =>
        (state: RootState, action: AnyAction) => {
            const combinedReducer = combineReducers({
                ...staticReducers,
                ...asyncReducers,
            })
            return combinedReducer(state, action)
        }

export default rootReducer
