import { v4 as uuidv4 } from 'uuid'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'


interface varValueProps {
    id: string
    value: string
}

interface variantProps {
    id?: string
    name: string
    value: varValueProps[]
    label?: string
}

export interface Variant {
    var_id: number
    var_name: string
    var_upc: string
    var_sku: string
    var_image: any[]
    var_dimension: string
    var_weight: number
    var_cost: number
    var_costing: string
    var_options: string | object
    brd_id: number
    prd_id: number
    created_by: any
    updated_by: any
    created_at: string
    updated_at: string
}

export interface variantOptInfoProps {
    id?: string
    name?: string
    measurement?: {
        width?: string
        height?: string
        length?: string
        weight?: string
    }
    inventoryIno?: {
        sku?: string
        barcode?: string
    }
    priceInfo?: {
        amazon?: string
        ebay?: string
        newegg?: string
        shopify?: string
        target?: string
        waltmart?: string
    }
    addedCost?: costArray[]
    warehouses?: warehouseProps[]
    image?: string[]
    upc?: string
    var_id?: number
    var_name?: string
    var_upc?: string
    var_sku?: string
    var_image?: string[]
    var_dimension?: string
    var_weight?: number
    var_cost?: number
    var_costing?: Record<string, string> | string
    var_options?: Record<string, string> | string
    brd_id?: number
    prd_id?: number
    created_by?: number
    updated_by?: number
    created_at?: string
    updated_at?: string
}

interface costArray {
    id?: string
    label?: string
    value?: string
    price?: string
}
interface warehouseProps {
    id: string
    name: string
}

interface EditUser {
    email: string
    role?: string
}

export type ProductState = {
    productFetch: any[]
    is_calender: boolean
    is_input: boolean
    is_variant: boolean
    variant_option: variantProps[]
    product_id: number,
    productIdShopify: any
    variant_option_info: variantOptInfoProps[]
    deleteVariants: any[]
    is_scm_single: boolean
    single_page: boolean
    is_alert: boolean
    is_reset_password: boolean
    is_alert_step: boolean
    outbound_true: boolean
    notification_calendar: boolean
    userEmailEdit: EditUser
    email: string
    is_user_option: string
    addProductForm: {
        prd_name: string
        prd_description: string
        prd_category: string
        prd_options: object
        brd_id: number
    }
    measurement: {
        width: string
        height: string
        length: string
        weight: string
    }
    inventoryIno: {
        sku: string
        barcode: string
    }
    priceInfo: {
        amazon: string
        ebay: string
        newegg: string
        shopify: string
        target: string
        waltmart: string
    }
    upc: string
    addedCost: costArray[]
    is_single_alert: boolean
    warehouses: warehouseProps[]
    totalCost: number
    single_product_variants: Variant[]
    selected_variant: Variant
    single_selected_product_id: string | number | null
    modal_selected_variant: Variant | null
    scmSelectedProduct: unknown
}

const initialState: ProductState = {
    productFetch: [],
    is_calender: false,
    is_input: false,
    is_alert_step: false,
    is_single_alert: false,
    product_id: 0,
    deleteVariants: [],
    notification_calendar: false,
    is_variant: false,
    is_alert: false,
    is_scm_single: false,
    single_page: false,
    is_reset_password: false,
    outbound_true: false,
    upc: '',
    email: '',
    productIdShopify: '',
    is_user_option: '',
    addProductForm: {
        prd_name: '',
        prd_description: '',
        prd_category: '',
        prd_options: {},
        brd_id: 3,
    },
    userEmailEdit: { email: '', role: '' },
    variant_option: [
        {
            id: uuidv4(),
            name: 'Size',
            value: [{ id: uuidv4(), value: 'Small' }],
        },
    ],
    variant_option_info: [],
    measurement: {
        weight: '',
        height: '',
        length: '',
        width: '',
    },
    inventoryIno: {
        sku: '',
        barcode: '',
    },
    priceInfo: {
        amazon: '',
        ebay: '',
        newegg: '',
        shopify: '',
        target: '',
        waltmart: '',
    },
    single_product_variants: [],
    addedCost: [],
    warehouses: [],
    totalCost: 0,
    selected_variant: {} as Variant,
    single_selected_product_id: null,
    modal_selected_variant: {} as Variant,
    scmSelectedProduct: null,
}

export const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setoutbound_true(state, action: PayloadAction<boolean>) {
            state.outbound_true = action.payload
        },

        set_is_alert_step(state, action: PayloadAction<boolean>) {
            state.is_alert_step = action.payload
        },
        setInput(state, action: PayloadAction<boolean>) {
            state.is_input = action.payload
        },
        setSingleProduct(state, action: PayloadAction<boolean>) {
            state.is_single_alert = action.payload
        },
        set_Notification_calendar(state, action: PayloadAction<boolean>) {
            state.notification_calendar = action.payload
        },
        setAlert(state, action: PayloadAction<boolean>) {
            state.is_alert = action.payload
        },
        setCalendar: (state, action: PayloadAction<boolean>) => {
            state.is_calender = action.payload
        },
        set_is_user_option: (state, action: PayloadAction<string>) => {
            state.is_user_option = action.payload
        },
        setProductIdShopify(state, action: PayloadAction<any>) {
            state.productIdShopify = action.payload
        },
        setEditUser: (state, action: PayloadAction<EditUser>) => {
            state.userEmailEdit = action.payload
        },
        setIsVariant: (state, action: PayloadAction<boolean>) => {
            state.is_variant = action.payload
        },
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload
        },
        setIsResetPass(state, action: PayloadAction<boolean>) {
            state.is_reset_password = action.payload
        },
        setVariantSingle: (state, action: PayloadAction<boolean>) => {
            state.is_scm_single = action.payload
        },
        setpageVariant: (state, action: PayloadAction<boolean>) => {
            state.single_page = action.payload
        },
        setVariantOption: (state, action: PayloadAction<variantProps[]>) => {
            const payload_id = [...new Set(action.payload.map((itr) => itr.id))]
            const variant_option_id = [...new Set(state.variant_option.map((itr) => itr.id))]
            if (payload_id.length != variant_option_id.length || !payload_id.every(id => variant_option_id.includes(id))) {
                state.variant_option = action.payload;
            }
        },
        setUpc: (state, action: PayloadAction<string>) => {
            state.upc = action.payload
        },
        addProduct: (
            state,
            action: PayloadAction<{
                prd_name: string
                prd_description: string
                prd_category: string
                prd_options: object
                brd_id: number
            }>
        ) => {
            state.addProductForm.prd_name = action.payload?.prd_name || ''
            state.addProductForm.prd_description =
                action.payload?.prd_description || ''
            state.addProductForm.prd_options = action.payload?.prd_options || ''
            state.addProductForm.prd_category =
                action.payload?.prd_category || ''
            state.addProductForm.brd_id = action.payload?.brd_id
        },
        deleteVariantOption(state, action: PayloadAction<{ id: string }>) {
            state.variant_option = state.variant_option.filter(
                (option) => option.id !== action.payload.id
            )
            if (!state.deleteVariants.includes(action.payload.id)) {
                state.deleteVariants.push(action.payload.id);
            }
        },
        setImage: (
            state,
            action: PayloadAction<{ id: string; images: string[] }>
        ) => {
            const variantInfo = state.variant_option_info.find(
                (item) => item.id === action.payload.id
            )
            if (variantInfo) {
                variantInfo.image = action.payload.images
            }
        },
        updateVariantValue: (
            state,
            action: PayloadAction<{ id: string; var_id: string; value: string }>
        ) => {
            state.variant_option.map((option) => {
                if (option.id !== action.payload.id) return
                option.value.map((data) => {
                    if (data.id !== action.payload.var_id) return
                    data.value = action.payload.value
                    return
                })
            })
        },
        set_ProductId(state, action: PayloadAction<number>) {
            state.product_id = action.payload
        },
        addInput: (
            state,
            action: PayloadAction<{ id: string; value: string }>
        ) => {
            state.variant_option.map((opt) => {
                if (opt.id !== action.payload.id) return
                opt.value = opt.value.concat({
                    id: uuidv4(),
                    value: action.payload.value,
                })
            })
        },
        updateVariant: (
            state,
            action: PayloadAction<{ id: string; name: string; label: string }>
        ) => {
            state.variant_option?.map((item) => {
                if (item.id === action.payload.id) {
                    ; (item.name = action.payload.name),
                        ((item as any).label = action.payload.label)
                }
            })
        },
        removeVariant: (state, action: PayloadAction<string>) => {
            state.variant_option?.map((variant) => {
                const res = variant?.value?.filter(
                    (i) => i.id !== action.payload
                )
                variant.value = res
            })
        },
        setTotalCost: (state, action: PayloadAction<number>) => {
            state.totalCost = action.payload
        },
        removeVariantArray: (state, action: PayloadAction<any[]>) => {
            state.deleteVariants = []
        },
        updateMeasurement: (
            state,
            action: PayloadAction<{
                width?: string
                height?: string
                length?: string
                weight?: string
            }>
        ) => {
            state.measurement.height = action.payload?.height || ''
            state.measurement.width = action.payload?.width || ''
            state.measurement.length = action.payload?.length || ''
            state.measurement.weight = action.payload?.weight || ''
        },
        updateInventoryInfo: (
            state,
            action: PayloadAction<{ sku?: string; barcode?: string }>
        ) => {
            state.inventoryIno.sku = action.payload?.sku || ''
            state.inventoryIno.barcode = action.payload?.barcode || ''
        },
        updatePriceInfo: (
            state,
            action: PayloadAction<{
                amazon?: string
                ebay?: string
                newegg?: string
                shopify?: string
                target?: string
                waltmart?: string
            }>
        ) => {
            state.priceInfo.amazon = action.payload?.amazon || ''
            state.priceInfo.ebay = action.payload?.ebay || ''
            state.priceInfo.newegg = action.payload?.newegg || ''
            state.priceInfo.shopify = action.payload?.shopify || ''
            state.priceInfo.target = action.payload?.target || ''
            state.priceInfo.waltmart = action.payload?.waltmart || ''
        },
        updateAddedCost: (state, action: PayloadAction<costArray>) => {
            state.addedCost = state.addedCost.concat({
                id: uuidv4(),
                label: action.payload?.label || '',
                value: action.payload?.value || '',
                price: action.payload?.price || '',
            })
        },
        bulkAddCost: (state, action: PayloadAction<costArray[]>) => {
            state.addedCost = action.payload
        },
        deleteAddedCost(state, action: PayloadAction<{ id: string }>) {
            state.addedCost = state.addedCost.filter(
                (item) => item.id !== action.payload.id
            )
        },
        updateCostPrice: (
            state,
            action: PayloadAction<{ id?: string; price?: string }>
        ) => {
            state.addedCost.map((cost) => {
                if (cost.id === action.payload.id) {
                    cost.price = action.payload.price
                    return
                }
            })
        },
        updateCostLabel: (
            state,
            action: PayloadAction<{
                id?: string
                label?: string
                value?: string
            }>
        ) => {
            state.addedCost.map((cost) => {
                if (cost.id === action.payload.id) {
                    cost.label = action.payload.label
                    cost.value = action.payload.value
                    return
                }
            })
        },
        updateWarehouse: (state, action: PayloadAction<{ name?: string }>) => {
            state.warehouses = state.warehouses.concat({
                id: uuidv4(),
                name: action.payload?.name || '',
            })
        },
        bulkUpdateWarehouse: (
            state,
            action: PayloadAction<warehouseProps[]>
        ) => {
            state.warehouses = action.payload
        },
        deleteWarehouse(state, action: PayloadAction<{ id: string }>) {
            state.warehouses = state.warehouses.filter(
                (item) => item.id !== action.payload.id
            )
        },
        addVariantOptInfo: (
            state,
            action: PayloadAction<variantOptInfoProps[]>
        ) => {
            state.variant_option_info = action.payload
        },

        updateVariantOptInfo: (state, action: PayloadAction<any>) => {
            state.variant_option_info.map((item) => {
                if (item.id === action.payload.id) {
                    item.inventoryIno = action.payload.inventory
                    item.measurement = action.payload.measurement
                    item.priceInfo = action.payload.priceInfo
                    item.warehouses = action.payload.wareHouse
                    item.addedCost = action.payload.addedCost
                }
            })
        },
        setSingleProductVariants: (state, action: PayloadAction<Variant[]>) => {
            state.single_product_variants = action.payload
        },
        setSingleSelectedProductId: (
            state,
            action: PayloadAction<string | number | null>
        ) => {
            state.single_selected_product_id = action.payload
        },
        setUpdateVariantOptionInfo: (state, action: PayloadAction<string>) => {
            state.variant_option_info=[...state.variant_option_info.filter(item => item.id !== action.payload)]
        },
        setSelectedVariant: (state, action: PayloadAction<Variant>) => {
            state.selected_variant = action.payload
        },
        setModalSelectedVariant: (
            state,
            action: PayloadAction<Variant | null>
        ) => {
            state.modal_selected_variant = action.payload
        },
        setAddedImage: (state, action: PayloadAction<any>) => {
            state.variant_option_info.map((itr) => {
                itr.image = action.payload.image
            })
        },
        setProductFetch: (state, action: PayloadAction<any[]>) => {
            state.productFetch = action.payload
        },
        setScmSelectedProduct: (state, action) => {
            state.scmSelectedProduct = action.payload
        },
        resetScmSelectedProduct: (state) => {
            state.scmSelectedProduct = null
        },
    },
})

export const {
    setIsVariant,
    setInput,
    setProductFetch,
    setCalendar,
    setoutbound_true,
    setUpc,
    set_is_alert_step,
    setVariantOption,
    deleteVariantOption,
    addProduct,
    setAlert,
    setSingleProduct,
    addInput,
    setEditUser,
    set_Notification_calendar,
    updateVariantValue,
    setAddedImage,
    removeVariant,
    setEmail,
    removeVariantArray,
    updateVariant,
    setIsResetPass,
    setpageVariant,
    setVariantSingle,
    updateMeasurement,
    updateInventoryInfo,
    set_is_user_option,
    updatePriceInfo,
    bulkAddCost,
    setImage,
    updateAddedCost,
    set_ProductId,
    deleteAddedCost,
    updateWarehouse,
    bulkUpdateWarehouse,
    deleteWarehouse,
    updateCostLabel,
    updateCostPrice,
    addVariantOptInfo,
    updateVariantOptInfo,
    setTotalCost,
    setSingleProductVariants,
    setSelectedVariant,
    setSingleSelectedProductId,
    setModalSelectedVariant,
    setScmSelectedProduct,
    setProductIdShopify,
    setUpdateVariantOptionInfo,
    resetScmSelectedProduct,
} = productSlice.actions
export default productSlice.reducer
