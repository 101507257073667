export type AppConfig = {
    apiPrefix: string
    authenticatedEntryPath: string
    unAuthenticatedEntryPath: string
    tourPath: string
    locale: string
    enableMock: boolean
}
// https://develop.backend.easymvp.methodeasy.res.alienyard.com

const appConfig: AppConfig = {
    apiPrefix:
        import.meta.env.MODE === 'development'
            ? 'http://localhost:8000'
            : 'https://develop.backend.easymvp.methodeasy.res.alienyard.com',
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
}

export default appConfig
