import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SLICE_BASE_NAME } from './constants'

export interface SwitcherProps {
    name: string
    logo: string
}

export type CommonState = {
    currentRouteKey: string
    seconderyNav: string
    module: SwitcherProps
}

export const initialState: CommonState = {
    currentRouteKey: '',
    seconderyNav: '',
    module: {
        name: '',
        logo: '',
    },
}

export const commonSlice = createSlice({
    name: `${SLICE_BASE_NAME}/common`,
    initialState,
    reducers: {
        setCurrentRouteKey: (state, action: PayloadAction<string>) => {
            state.currentRouteKey = action.payload
        },
        setSecondaryNav: (state, action: PayloadAction<string>) => {
            state.seconderyNav = action.payload
        },
        setPageModule: (state, action: PayloadAction<SwitcherProps>) => {
            state.module.name = action.payload.name
            state.module.logo = action.payload.logo
        },
    },
})

export const { setCurrentRouteKey, setSecondaryNav, setPageModule } =
    commonSlice.actions

export default commonSlice.reducer
