import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import Theme from '@/components/template/Theme'
import Layout from '@/components/layouts'
import mockServer from './mock'
import appConfig from '@/configs/app.config'
import './locales'
import ScrollBar from './components/ui/ScrollBar'
import { Auth0Provider } from '@auth0/auth0-react'

const environment = process.env.NODE_ENV

/**
 * Set enableMock(Default false) to true at configs/app.config.js
 * If you wish to enable mock api
 */
// hello
if (environment !== 'production' && appConfig.enableMock) {
    mockServer({ environment })
}

function App() {
    return (
        <ScrollBar
            autoHide={true}
            autoHideTimeout={1000}
            autoHideDuration={200}
            style={{ width: '100vw', height: '100vh' }}
        >
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter>
                        <Theme>
                            <Auth0Provider
                                domain={import.meta.env.VITE_DOMAIN}
                                clientId={
                                    environment === 'development'
                                        ? import.meta.env.VITE_DEV_CLIENT_ID
                                        : import.meta.env.VITE_CLIENT_ID
                                }
                                authorizationParams={{
                                    // scope: "openid profile email",
                                    audience:
                                        import.meta.env.VITE_AUTH0_AUDIENCE,

                                }}
                                cacheLocation="localstorage"
                            >
                                <Layout />
                            </Auth0Provider>
                        </Theme>
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        </ScrollBar>
    )
}

export default App
