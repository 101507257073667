import { apiCreateOrganization, apiSignIn, apiSignOut, apiSignUp } from '@/services/AuthService'
import {
    setUser,
    signInSuccess,
    signOutSuccess,
    useAppSelector,
    useAppDispatch,
    setOrganization,
    signIdToken,
    setVerifiedEmail,
    // setVerifiedOrgId,
} from '@/store'
import appConfig from '@/configs/app.config'
import { REDIRECT_URL_KEY } from '@/constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import type { SignInCredential, SignUpCredential, createOrganization } from '@/@types/auth'
import { useAuth0 } from '@auth0/auth0-react'
// import { Loading } from '../../components/shared'
import { initialState, setVerifiedOrgId } from '../../store/slices/auth/userSlice'

type Status = 'success' | 'failed'

function useAuth() {
    const dispatch = useAppDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, id_token, signedIn } = useAppSelector((state) => state.auth.session)

    const { user, getAccessTokenSilently, logout } = useAuth0()
    // const { signUp } = useAuth()


    const signIn = async (
        values?: SignInCredential
    ): Promise<
        | {
            status: Status
            message: string
        }
        | undefined
    > => {
        try {
            // const resp = await apiSignIn(values)
            const token = await getAccessTokenSilently()
            dispatch(signInSuccess(token))
            dispatch(
                setUser({
                    avatar: user?.picture,
                    userName: user?.email?.split('@')[0],
                    authority: ['admin', 'user'],
                    email: user?.email,
                })
            )
            dispatch(setVerifiedEmail(user?.email_verified))

        } catch (errors: any) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const signUp = async (values: SignUpCredential) => {
        try {
            const resp = await apiSignUp(values)
            if (resp.data) {
                const { token } = resp.data
                dispatch(signInSuccess(token))
                await new Promise((resolve) => setTimeout(resolve, 500));
                // const id_token = await getIdTokenClaims()
                // const id_token_string = JSON.stringify(id_token)
                dispatch(signIdToken({
                    signedIn: true,
                    id_token: id_token
                }))
                console.log(id_token)
                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    )
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        } catch (errors: any) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(signOutSuccess())

        dispatch(
            setUser(initialState)
        )
        navigate(appConfig.unAuthenticatedEntryPath)
        // dispatch(setVerifiedOrgId(""))
    }

    const signOut = async () => {
        try {
            await logout();
            handleSignOut();
            // dispatch(setVerifiedOrgId(""));
        } catch (error) {
            console.error("Logout failed", error);
        }

    }

    const createOrganization = async (values: createOrganization) => {
        try {
            const resp = await apiCreateOrganization(values)
            console.log(resp)
            if (resp.data) {
                dispatch(
                    setOrganization(
                        resp.data || {
                            display_name: '',
                            org_country: '',
                            org_tmz: 'TimeZone',
                            org_legal_id: '',
                            org_legal_name: '',
                            org_url: ''
                        }
                    )
                )

                navigate(
                    appConfig.authenticatedEntryPath
                )

                return {
                    status: 'success',
                    message: resp.data
                }
            }
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        } catch (errors: any) {
            console.log(errors)
            return {
                status: 'failed',
                message: "User is already a member of an organization",
            }
        }
    }

    return {
        authenticated: token && signedIn, //Basically for local use
        signIn,
        signUp,
        signOut,
        createOrganization,
    }
}

export default useAuth


//In future we will reuse don't remove ...any developer


// dispatch(signInSuccess('wVYrxaeNa9OxdnULvde1Au5m5w63'))
// dispatch(
//     setUser({
//         avatar: '/img/avatars/thumb-2.jpg',
//         userName: 'Shazor Khan',
//         authority: ['admin', 'user'],
//         email: 'shazor@methodeasy.com',
//     })
// )

// if (resp.data) {
//     const { token } = resp.data
//     dispatch(signInSuccess('wVYrxaeNa9OxdnULvde1Au5m5w63'))
//     if (resp.data.user) {
//         dispatch(
//             setUser(
//                 resp.data.user || {
//                     avatar: '',
//                     userName: 'Anonymous',
//                     authority: ['USER'],
//                     email: '',
//                 }
//             )
//         )
//     }
//     const redirectUrl = query.get(REDIRECT_URL_KEY)
//     navigate(
//         redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
//     )
//     return {
//         status: 'success',
//         message: '',
//     }
// }
// eslint-disable-next-line  @typescript-eslint/no-explicit-any

// await getIdTokenClaims().then((res) => {
//     dispatch(signIdToken({
//         signedIn: isAuthenticated,
//         id_token: res as IdToken
//     }))
// }).catch((err) => {
//     console.log(err)
// })