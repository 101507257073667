import { optionProps } from '@/@types/common'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export type InsightSalesState = {
    breakdownChannel: optionProps
    compareProduct: optionProps
    breakdownEcom1: optionProps
    breakdownEcom2: optionProps
    breakdownProd1: optionProps
    breakdownProd2: optionProps
}

const initialState: InsightSalesState = {
    breakdownChannel: { label: 'Sales Channel', value: '0' },
    compareProduct: { label: 'Sales Channel', value: '0' },
    breakdownEcom1: { label: 'Amazon', value: '0' },
    breakdownEcom2: { label: 'Shopify', value: '1' },
    breakdownProd1: { label: 'ProductX', value: '0' },
    breakdownProd2: { label: 'ProductY', value: '1' },
}

export const insightSalesSlice = createSlice({
    name: 'insightSales',
    initialState,
    reducers: {
        setBreakdownChannel(state, action: PayloadAction<optionProps>) {
            state.breakdownChannel = action.payload
        },
        setCompareProduct(state, action: PayloadAction<optionProps>) {
            state.compareProduct = action.payload
        },
        setBreadownEcom1(state, action: PayloadAction<optionProps>) {
            state.breakdownEcom1 = action.payload
        },
        setBreadownEcom2(state, action: PayloadAction<optionProps>) {
            state.breakdownEcom2 = action.payload
        },
        setBreakdownProd1(state, action: PayloadAction<optionProps>) {
            state.breakdownProd1 = action.payload
        },
        setBreakdownProd2(state, action: PayloadAction<optionProps>) {
            state.breakdownProd2 = action.payload
        },
    },
})

export const {
    setBreakdownChannel,
    setCompareProduct,
    setBreadownEcom1,
    setBreadownEcom2,
    setBreakdownProd1,
    setBreakdownProd2,
} = insightSalesSlice.actions
export default insightSalesSlice.reducer
